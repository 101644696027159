<script lang="ts" setup>
import type { NavigationTab } from '@/types/layout';

interface Props {
  tab: NavigationTab;
}

const props = defineProps<Props>();

const pageIconName = computed(() => {
  return `pages/${props.tab.icon}`;
});
</script>
<template>
  <NuxtLink :key="tab.url" :to="tab.url" :class="['page-link', tab.className]">
    <span class="link-rectangle link-rectangle__left">
      <NuxtIcon name="rectangle-left" filled />
    </span>
    <span class="link-icon">
      <NuxtIcon :name="pageIconName" filled />
    </span>
    <span class="hidden md:inline">{{ tab.name }}</span>
    <span class="link-rectangle link-rectangle__right">
      <NuxtIcon name="rectangle-right" filled />
    </span>
  </NuxtLink>
</template>

<style lang="scss" scoped>
.page-link {
  @apply flex relative justify-center items-center h-[46px] md:h-[65px] px-7 py-2.5 md:py-3.5 text-brand-navy-700 md:text-white text-[22px] leading-6 z-10;

  .link-icon {
    :deep(.nuxt-icon svg) {
      @apply w-6 h-6 mb-0 md:mr-2.5;
    }
  }

  .link-rectangle {
    @apply absolute -bottom-[1px] text-brand-navy-150 md:text-brand-navy-300 z-10;

    &__left {
      @apply -left-[11px];
    }

    &__right {
      @apply -right-[11px];
    }

    :deep(.nuxt-icon svg) {
      @apply w-3 h-3 mb-0;
    }
  }

  &.router-link-active {
    &.router-link-exact-active {
      @apply bg-brand-white text-brand-navy-700 rounded-t-[10px] z-20;

      .link-rectangle {
        @apply text-brand-white z-20;
      }
    }
  }
}
</style>
