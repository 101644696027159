<script lang="ts" setup>
import type { Plugin } from 'chart.js';
import useLevelIcons from '@/composables/helpers/useLevelIcons';
import useTailwindConfig from '@/composables/helpers/useTailwindConfig';
import type { DataSet } from '@/types/charts';

const { theme } = useTailwindConfig();

interface Props {
  datasets: DataSet<number>[];
  labels: string[];
  className?: string;
}

const props = withDefaults(defineProps<Props>(), {
  datasets: () => []
});

const MIN_Y = 1;

const POINT_WIDTH = 30;
const POINT_HEIGHT = 26;
const POINT_BORDER_RADIUS = 20;

const ICON_WIDTH = 14;
const ICON_HEIGHT = 15;

const compactLevelPoints: Plugin = {
  id: 'compactLevelPoints',
  afterDatasetsDraw(chart, _args, _options) {
    const { ctx, data } = chart;

    ctx.save();

    for (const dataSetIndex in props.datasets) {
      const dataset = props.datasets[dataSetIndex];
      chart
        .getDatasetMeta(parseInt(dataSetIndex))
        .data.forEach((dataPoint, index) => {
          ctx.fillStyle =
            dataset.borderColor || theme?.colors['brand-light-gray'];
          ctx.beginPath();
          ctx.roundRect(
            dataPoint.x - POINT_WIDTH / 2,
            dataPoint.y - POINT_HEIGHT / 2,
            POINT_WIDTH,
            POINT_HEIGHT,
            POINT_BORDER_RADIUS
          );
          ctx.fill();

          const pointData = Number(data?.datasets[dataSetIndex]?.data[index]);

          if (pointData) {
            const level = Math.floor(pointData);
            useLevelIcons(level, ICON_WIDTH, ICON_HEIGHT).then((icon) => {
              ctx.drawImage(
                icon,
                dataPoint.x - ICON_WIDTH / 2,
                dataPoint.y - ICON_HEIGHT / 2,
                ICON_WIDTH,
                ICON_HEIGHT
              );
            });
          }
        });
    }
  }
};
</script>
<template>
  <div :class="[className]">
    <LineChart
      :labels="labels"
      :datasets="datasets"
      :min-y="MIN_Y"
      :x-ticks-padding="10"
      :plugins="[compactLevelPoints]"
    />
  </div>
</template>
