<script lang="ts" setup>
import { HandleStatus } from '@/types/tournaments';

interface Props {
  status: HandleStatus;
  dayName: string;
}

const props = withDefaults(defineProps<Props>(), {
  status: HandleStatus.Upcoming
});

const statusIconName = computed(() => {
  return `statuses/${props.status}`;
});
</script>

<template>
  <div class="inline-flex flex-col items-center">
    <NuxtIcon :name="statusIconName" filled class="mb-2" />
    <span
      :class="[
        'text-base text-center text-brand-navy-700',
        status === HandleStatus.Ongoing ? 'font-bold' : 'font-normal'
      ]"
    >
      {{ dayName }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
:deep(.nuxt-icon svg) {
  @apply w-[30px] h-[30px] mb-0;
}
</style>
