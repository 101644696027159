<script lang="ts" setup>
import type { StreakDay } from '@/types/tournaments';

interface Props {
  streak: StreakDay[];
  maxStreak?: number;
  rating?: number;
}

const props = withDefaults(defineProps<Props>(), {
  streak: () => [],
  maxStreak: 0,
  rating: 1
});

const levelIconName = computed(() => {
  return `levels/${Math.floor(props.rating)}`;
});
</script>

<template>
  <div class="grid auto-rows-min gap-4 place-items-start">
    <div class="grid grid-cols-2 w-full">
      <div class="flex items-center">
        <NuxtIcon name="fire" filled class="fire-icon mr-2" />
        <h3
          class="text-lg leading-6 md:text-2xl font-medium text-brand-navy-700"
        >
          {{ maxStreak }}
        </h3>
        <span
          class="hidden md:inline text-base leading-5 font-medium text-brand-navy-700 ml-2"
        >
          Streak
        </span>
      </div>
      <div class="flex items-center">
        <NuxtIcon :name="levelIconName" filled class="level-icon mr-2" />
        <h3
          class="text-lg leading-6 md:text-2xl font-medium text-brand-navy-700"
        >
          {{ rating }}
        </h3>
        <span
          class="hidden md:inline text-base leading-5 font-medium text-brand-navy-700 ml-2"
        >
          Level
        </span>
      </div>
    </div>
    <div
      v-if="streak.length > 0"
      class="hidden md:flex w-full justify-between pt-4 border-t border-brand-light-gray"
    >
      <StreakDay
        v-for="(day, index) in streak"
        :key="index"
        :status="day.status"
        :day-name="day.dayName"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fire-icon {
  :deep(svg) {
    @apply w-6 h-6 md:w-7 md:h-7 mb-0;
  }
}
.level-icon {
  :deep(svg) {
    @apply w-5 h-5 md:w-6 md:h-6 mb-0;
  }
}
</style>
