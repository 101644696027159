<script lang="ts" setup>
import { useModalStore } from '@/stores/modal';
import type { StreakDay } from '@/types/tournaments';

const { props } = useModalStore();

const streak = ref<StreakDay[]>(props.streak || []);

const maxStreak = ref<number>(props.maxStreak || 0);

const rating = ref<number>(props.rating || 1);

const levelIconName = computed(() => {
  return `levels/${Math.floor(props.rating)}`;
});
</script>

<template>
  <Modal title="This week" size="sm">
    <div
      class="grid auto-rows-min gap-3 place-items-start p-4 bg-white rounded-lg"
    >
      <div class="grid grid-cols-2 w-full">
        <div class="flex items-center">
          <NuxtIcon name="fire" filled class="fire-icon mr-2" />
          <h3 class="text-2xl font-medium text-brand-navy-700">
            {{ maxStreak }}
          </h3>
          <span
            class="text-base leading-5 font-medium text-brand-navy-700 ml-2"
          >
            Streak
          </span>
        </div>
        <div class="flex items-center">
          <NuxtIcon :name="levelIconName" filled class="level-icon mr-2" />
          <h3 class="text-2xl font-medium text-brand-navy-700">
            {{ rating }}
          </h3>
          <span
            class="text-base leading-5 font-medium text-brand-navy-700 ml-2"
          >
            Level
          </span>
        </div>
      </div>
      <div
        v-if="streak.length > 0"
        class="flex w-full justify-between pt-3 border-t border-brand-light-gray"
      >
        <StreakDay
          v-for="(day, index) in streak"
          :key="index"
          :status="day.status"
          :day-name="day.dayName"
        />
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.fire-icon {
  :deep(svg) {
    @apply w-7 h-7 mb-0;
  }
}
.level-icon {
  :deep(svg) {
    @apply w-6 h-6 mb-0;
  }
}
</style>
