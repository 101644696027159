<script lang="ts" setup>
import { useAuthStore } from '@/stores/auth';
import { useTournamentStore } from '@/stores/tournaments';
import { useModalStore } from '@/stores/modal';
import FourWeeksLevelProgressChart from '@/components/StatsOverview/FourWeeksLevelProgressChart.vue';
import HandleRating from '@/components/StatsOverview/HandleRating.vue';
import AvatarSelectionModal from '@/components/Account/AvatarSelection/AvatarSelectionModal.vue';
import HandleRatingModal from '@/components/StatsOverview/HandleRatingModal.vue';
import HandleStreakModal from '@/components/StatsOverview/HandleStreakModal.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import { type StreakDay } from '@/types/tournaments';
import { type DataSet } from '@/types/charts';
import moment from 'moment';
import { useLevelProgressStore } from '@/stores/statistic/levelProgress';

interface Props {
  className?: string;

  [x: string]: any;
}

defineProps<Props>();

const {
  profile,
  userCreatedDate,
  premiumAccess,
  userRating,
  userWeeklyRating,
  handleStreak
} = storeToRefs(useAuthStore());
const {
  weeklyHandles,
  currentHandle,
  ongoingHandleExists,
  timeUntilNextHandle,
  timeUntilNextTournament
} = storeToRefs(useTournamentStore());
const { fourWeeksData, fourWeeksDataIsEmpty } = storeToRefs(
  useLevelProgressStore()
);
const { playHandle } = useTournamentStore();
const { getFourWeeksData } = useLevelProgressStore();
const { openModal } = useModalStore();

await useAsyncData('level-progress', () => getFourWeeksData());

const loading = ref(false);

const levelProgressPoints = ref<DataSet<number>[]>([
  {
    data: fourWeeksData.value.map((item) => item.value)
  }
]);

const levelProgressLabels = computed<string[]>(() => {
  return fourWeeksData.value.map((item) => item.label);
});

const userFirstName = computed(() => {
  return profile.value?.firstName;
});

const accountCreatedAt = computed(() => {
  return `Player since ${userCreatedDate.value}`;
});

const locked = computed(() => {
  return !premiumAccess.value;
});

const lockedContentClass = 'h-full md:!h-40 bottom-0 left-0 rounded-b-[5px]';

const streak = computed<StreakDay[]>(() => {
  return weeklyHandles.value.map((handle) => {
    const dayName = moment(handle.date).format('ddd');

    return {
      status: handle.status,
      dayName
    };
  });
});

const disableButtonText = computed(() => {
  if (premiumAccess.value) {
    return `Unlocks ${timeUntilNextHandle.value}`;
  }

  return `Unlocks ${timeUntilNextTournament.value}`;
});

function openAvatarSelectionModal() {
  openModal({ modal: AvatarSelectionModal });
}

function openHandleRatingModal() {
  openModal({ modal: HandleRatingModal, props: { rating: userRating } });
}

function openHandleStreakModal() {
  openModal({
    modal: HandleStreakModal,
    props: { streak: streak, maxStreak: handleStreak, rating: userWeeklyRating }
  });
}

async function handlePlayNow() {
  if (currentHandle.value && !loading.value) {
    loading.value = true;

    const id = currentHandle.value.id;

    await playHandle(id);

    loading.value = false;
  }
}
</script>

<template>
  <div
    :class="[
      'grid grid-cols-3 gap-6 bg-brand-white px-5 md:px-8 py-8 rounded-[10px]',
      className
    ]"
  >
    <div
      class="col-span-3 md:col-span-2 flex justify-between items-center order-1"
    >
      <div class="grid grid-cols-[auto_1fr] max-w-full">
        <div
          class="flex relative cursor-pointer"
          @click="openAvatarSelectionModal"
        >
          <Tooltip content="Change avatar" class-name="whitespace-nowrap">
            <Avatar
              :image="profile?.avatarImage"
              :color="profile?.avatarColor"
              class-name="!w-[50px] !h-[50px] md:!w-[72px] md:!h-[72px]"
            />
            <NuxtIcon name="pen" filled class="edit-icon" />
          </Tooltip>
        </div>
        <div
          class="grid grid-rows-[auto_auto] items-center justify-center md:py-2 ml-2.5 md:ml-3.5"
        >
          <h2
            class="text-[22px] md:text-[26px] leading-7 md:leading-8 font-bold truncate"
          >
            {{ userFirstName }}
          </h2>
          <p class="text-lg text-brand-navy-200 leading-6">
            {{ accountCreatedAt }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="col-span-3 md:col-span-1 order-3 md:order-2 flex justify-end items-center"
    >
      <Button
        v-if="ongoingHandleExists"
        start-icon="ion:play"
        class-name="w-full md:w-fit"
        @click="handlePlayNow"
      >
        Play Now
      </Button>
      <Button
        v-else
        color="secondary"
        start-icon="ion:play-outline"
        class-name="w-full md:w-fit"
        disabled
      >
        {{ disableButtonText }}
      </Button>
    </div>
    <div
      class="col-span-3 grid grid-cols-2 md:grid-cols-3 gap-[15px] order-2 md:order-3"
    >
      <Card
        class-name="gap-3 md:gap-5 pt-3 md:pt-5 md:pb-3 pr-4 md:pr-5 relative"
      >
        <h4 class="hidden md:block text-brand-navy-200 text-lg leading-6">
          Overall Handle Rating
        </h4>
        <div class="flex md:hidden justify-between items-end">
          <h4 class="text-brand-navy-200 text-lg leading-[22px]">All time</h4>
          <NuxtIcon
            name="expand"
            filled
            class="expand-icon"
            @click="openHandleRatingModal"
          />
        </div>
        <HandleRating :rating="userRating" />
        <LockedContentOverlay v-if="locked" :class="lockedContentClass" />
      </Card>
      <Card
        title="Last 4 Weeks"
        class-name="grid-cols-1 col-span-2 md:col-span-1 pb-4 relative order-3 md:order-2"
      >
        <FourWeeksLevelProgressChart
          :datasets="levelProgressPoints"
          :labels="levelProgressLabels"
          class-name="h-32"
        />
        <LockedContentOverlay v-if="locked" :class="lockedContentClass" />
        <ChartPlaceholder
          v-else-if="fourWeeksDataIsEmpty"
          :class="lockedContentClass"
        />
      </Card>
      <Card
        class-name="gap-4 md:gap-5 pt-3 md:pt-5 md:pb-3 pt-3 md:pt-5 relative order-2 md:order-3"
      >
        <h4 class="hidden md:block text-brand-navy-200 text-lg leading-6">
          This week
        </h4>
        <div class="flex md:hidden justify-between items-end">
          <h4 class="text-brand-navy-200 text-lg leading-[22px]">Week</h4>
          <NuxtIcon
            name="expand"
            filled
            class="expand-icon"
            @click="openHandleStreakModal"
          />
        </div>
        <HandleStreak
          :streak="streak"
          :max-streak="handleStreak"
          :rating="userWeeklyRating"
        />
        <LockedContentOverlay v-if="locked" :class="lockedContentClass" />
      </Card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.edit-icon {
  @apply absolute bottom-0 right-0;

  :deep(svg) {
    @apply w-5 h-5 mb-0;
  }
}

.expand-icon {
  @apply cursor-pointer;

  :deep(svg) {
    @apply w-[30px] h-[30px] mb-0;
  }
}
</style>
