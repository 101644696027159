<script lang="ts" setup>
import { showDecimals } from '@/utils/helpers';
import { useModalStore } from '@/stores/modal';
import PointsSystemModal from '@/components/StatsOverview/PointsSystemModal.vue';

const { openModal } = useModalStore();

interface Props {
  rating: number;
}

const props = withDefaults(defineProps<Props>(), { rating: 0 });

const ratingString = computed(() => {
  return showDecimals(props.rating, 1);
});

const levelProgress = computed(() => {
  return Math.floor((props.rating % 1) * 100);
});

function openPointsSystemModal() {
  openModal({ modal: PointsSystemModal });
}
</script>

<template>
  <div class="grid auto-rows-min gap-4 place-items-start">
    <div class="flex items-center">
      <NuxtIcon name="logo" filled class="mr-3.5" />
      <h3
        class="text-[26px] leading-[34px] md:text-[32px] md:leading-[42px] font-medium text-brand-navy-700"
      >
        {{ ratingString }}
      </h3>
    </div>
    <ProgressBar :value="levelProgress" class-name="hidden md:block" />
    <div class="hidden md:flex items-start text-brand-navy-200">
      <Icon name="icons8:info" size="20" class="mt-0.5 mr-1" />
      <p class="text-base leading-5">
        See how we
        <!-- prettier-ignore -->
        <span class="underline cursor-pointer" @click="openPointsSystemModal">calculate points</span>
        and your weekly & overall Handle ratings
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.nuxt-icon svg) {
  @apply w-7 h-7 mb-0;
}
</style>
