<script lang="ts" setup></script>

<template>
  <Modal title="Handle points system">
    <h1>How Your Handle Rating Works:</h1>
    <h2>Each Week</h2>
    <ul>
      <li>
        All players start on 1.0 rating and will reach 2.0 by winning Sunday’s
        level 2 deal.
      </li>
      <li>
        Each overtrick adds 0.1 to your rating, e.g. two overtricks on Sunday =
        2.2 rating.
        <ul>
          <li>
            Your overtricks are totalled up over the whole week and added to
            your rating.
          </li>
        </ul>
      </li>
      <li>
        If you succeed on Monday (level 3) your rating goes up to 3.0 (plus ALL
        overtricks).
      </li>
      <li>
        If you succeed on Tuesday (level 4) your rating goes up to 4.0 (plus ALL
        overtricks).
      </li>
      <li>
        If you succeed on Wednesday (level 5) your rating goes up to 5.0 (plus
        ALL overtricks).
      </li>
      <li>
        If you succeed on Thursday (level 6) your rating goes up to 6.0 (plus
        ALL overtricks).
      </li>
      <li>
        If you succeed on Friday (level 7) your rating goes up to 7.0 (plus ALL
        overtricks).
      </li>
      <li>
        If you succeed on Saturday (level 8) your rating goes up to 8.0 (plus
        ALL overtricks).
      </li>
      <li>
        If you fail, your rating remains the same as your previous success.
      </li>
      <li>
        If you then succeed on a subsequent day (having failed), you lose 0.5
        from your rating for each level failed previously that week.
      </li>
      <li>
        E.g. If you succeed on level 5 (but not 6, 7 or 8) but failed on level
        4, and have achieved two overtricks during the week, your weekly Handle
        rating would be 5 – 0.5 + 0.2 = 4.7.
      </li>
      <li>
        NB If you miss a daily Handle, it does not count (positively or
        negatively), though may count as a missed week, see below.
      </li>
    </ul>
    <h2>Ongoing Handle Rating, Streak & Stats</h2>
    <ul>
      <li>
        Your ongoing Handle rating (HR) is calculated by the average of the last
        4 weeks played.
      </li>
      <li>
        E.g. Wk 1: 4.7 (HR = 4.7) Wk 2: 5.3 (HR = 5.0). Wk 3: 6.2 (HR = 5.4), Wk
        4: 4.6. <b>HR = 5.2</b>.
      </li>
      <li>
        After Week 5, week 1 will then be ignored with the average of weeks 2 to
        5 as your rating.
      </li>
      <li>Your rating will decay by 0.1 for each missed week of Handle.</li>
      <li>
        Your rating will decay by 0.1 if you have not attempted a Handle above
        your rating, but have not failed any Handle of that week.
      </li>
      <li>
        Your Handle streak is the number of Handles played in a row - win or
        lose.
      </li>
      <li>View your stats and progress graphs over different time periods.</li>
      <li>Compare your trump play with your play in NTs.</li>
    </ul>
  </Modal>
</template>
