<script lang="ts" setup>
import { useModalStore } from '@/stores/modal';
import PointsSystemModal from '@/components/StatsOverview/PointsSystemModal.vue';

const { openModal, props } = useModalStore();

const rating = ref<number>(props.rating || 0);

const levelProgress = computed(() => {
  return Math.floor((rating.value % 1) * 100);
});

function openPointsSystemModal() {
  openModal({ modal: PointsSystemModal });
}
</script>

<template>
  <Modal title="Overall Handle Rating" size="sm">
    <div class="grid auto-rows-min gap-4 place-items-start">
      <div class="grid gap-4 w-full p-4 bg-white rounded-lg">
        <div class="flex items-center">
          <NuxtIcon name="logo" filled class="mr-3.5" />
          <h3
            class="text-[26px] leading-[34px] font-medium text-brand-navy-700"
          >
            <span>Level</span> {{ rating }}
          </h3>
        </div>
        <ProgressBar :value="levelProgress" />
      </div>
      <div class="flex items-start text-brand-navy-200">
        <Icon name="icons8:info" size="20" class="mt-0.5 mr-1" />
        <p class="text-base leading-5">
          See how we
          <span class="underline cursor-pointer" @click="openPointsSystemModal">
            calculate points
          </span>
          and your weekly & overall Handle ratings
        </p>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
:deep(.nuxt-icon svg) {
  @apply w-7 h-7 mb-0;
}
</style>
