<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';

interface Props {
  className?: string;
}

defineProps<Props>();

const { daysUntilPlanExpiration } = storeToRefs(useAuthStore());

const goPremiumLink = computed(() => {
  // TODO: May be changed later
  return '/settings/handle';
});

const reminderIconName = computed(() => {
  if (daysUntilPlanExpiration.value > 1) {
    return 'reminder-clock';
  }
  return 'reminder-fire';
});

const reminderTitle = computed(() => {
  if (daysUntilPlanExpiration.value > 1) {
    return `Only ${daysUntilPlanExpiration.value} days left on your free to go`;
  }
  if (daysUntilPlanExpiration.value >= 0) {
    return 'Oh no! Your free go is at an end';
  }
  return 'Unlock all features with a Bridge128 Premium subscription';
});
</script>

<template>
  <div
    :class="[
      'grid grid-cols-4 gap-3 md:gap-1 bg-brand-lightest-blue rounded-[10px] p-5',
      className
    ]"
  >
    <div class="grid col-span-4 md:col-span-3 gap-1.5">
      <h3
        class="flex items-center text-xl md:text-[22px] md:leading-[30px] text-brand-navy-600 font-medium"
      >
        <NuxtIcon
          :name="reminderIconName"
          filled
          class="reminder-icon mr-1.5"
        />{{ reminderTitle }}
      </h3>
      <p class="text-base md:text-lg leading-6 text-brand-navy-200">
        Go Premium now not to loose your streak and stats, and continue to play
        every day!
      </p>
    </div>
    <div class="col-span-4 md:col-span-1 flex md:justify-end items-center">
      <Button
        color="secondary"
        class-name="w-full md:w-fit"
        :to="goPremiumLink"
      >
        Go Premium now
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.reminder-icon svg) {
  @apply w-[30px] h-[30px] mb-0;
}
</style>
