<script lang="ts" setup>
import type { NavigationTab } from '@/types/layout';
import AppPageLink from '@/components/core/AppPageLink.vue';

interface Props {
  tabs?: NavigationTab[];
}

const props = withDefaults(defineProps<Props>(), {
  tabs: () => []
});

const wrapperStyle = computed(() => {
  return {
    gridTemplateColumns: `repeat(${props.tabs.length}, minmax(0, 1fr))`
  };
});
</script>
<template>
  <div class="app-navigation-wrapper" :style="wrapperStyle">
    <AppPageLink v-for="tab in tabs" :key="tab.url" :tab="tab" />
  </div>
</template>

<style lang="scss" scoped>
.app-navigation-wrapper {
  @apply grid bg-brand-navy-150 md:bg-brand-navy-300 rounded-t-[10px] overflow-y-clip;
}
</style>
